<picture class="h-full w-full object-contain">
  @for (source of sources(); track source.src) {
    <source [srcset]="source.src" [type]="source.type" />
  }
  <img
    class="h-full w-full object-contain"
    [loading]="loading()"
    [title]="alt()"
    [src]="source() + '.' + extension()"
    [alt]="alt()"
  />
</picture>
