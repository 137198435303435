import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';

@Component({
    selector: 'app-static-asset',
    imports: [],
    templateUrl: './static-asset.component.html',
    styleUrl: './static-asset.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticAssetComponent {
  source = input.required<string>();
  extension = input.required<string>();
  alt = input.required<string>();

  extensions = input<string[]>(['webp', 'avif']);

  loading = input<'lazy' | 'eager'>('lazy');

  sources = computed(() => {
    return this.extensions().map((ext) => ({
      src: `${this.source()}.${ext}`,
      type: this.getMediaType(ext),
    }));
  });

  getMediaType(extension: string) {
    if (extension === 'png') {
      return 'image/png';
    } else if (extension === 'webp') {
      return 'image/webp';
    } else if (extension === 'jpeg') {
      return 'image/jpeg';
    } else if (extension === 'jpg') {
      return 'image/jpg';
    } else if (extension === 'avif') {
      return 'image/avif';
    } else {
      return '';
    }
  }
}
